<template>
  <NavBar />
  <notifications position="top right" />
  <app-loader :is-loading="loading" /> <!-- Use o componente AppLoader -->

  <router-view />

  <FooterView class="mt-10" />
</template>

<script>
import NavBar from '@/components/Default/NavBar.vue'
// import PulseLoader from 'vue-spinner/src/PulseLoader.vue'  LP COMMENTED FOR CHANGE LOADER FOR LOADING.GIF
import AppLoader from '@/components/AppLoader.vue' // LP ADDED FOR LOADING.GIF - Importe o componente AppLoader

import FooterView from '@/components/Default/Footer.vue'
export default {
  name: 'AppView',
  data() {
    return {
      home: 'Home',
      color: '#080F0E'
    }
  },
  components: {
    NavBar,
    FooterView,
    'app-loader': AppLoader, //  LP ADDED FOR LOADING.GIF - Use o componente AppLoader
  //  PulseLoader, LP COMMENTED FOR CHANGE LOADER FOR LOADING.GIF
  },

  mounted() {
    document.title = "Poptudo - Até 15% +barato que iFood"
  },
  computed: {
    loading() {
      return this.$store.getters.loading
    }
  },

}
</script>

  

