<!-- LP ADDED , CREATED THIS FILE FOR LOADING.GIF components/AppLoader.vue -->

<template>
  <div v-if="isLoading" class="loader">
    <img src="/img/loading.gif" alt="Carregando..." />
  </div>
</template>

<script>
export default {
  name: 'AppLoader', 
  props: {
    isLoading: Boolean,
  },
};
</script>

<style scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
</style>